import { StyleSheet } from 'react-native';
import { Box, Typography, Button } from '@mui/material';
import { useThemeContext } from '../../hook/useThemeManager';
import { ColorScheme, theme } from '../../constants/theme';
import { FONT_ENUM } from '../../types/global/fonts.types';
import CustomLazyLoadImage from '../Image/CustomLazyLoadImage';
import { useEffect, useState } from 'react';

interface IEmptyContent {
    outlineIcon: string;
    outlineIconAlt: string;
    filledIcon: string;
    filledIconAlt: string;
    title: string;
    description: string;
    onClickHandle?: () => void;
    buttonText: string;
}

const EsEmptyContentCard = ({
    outlineIcon,
    outlineIconAlt,
    filledIcon,
    filledIconAlt,
    title,
    description,
    onClickHandle,
    buttonText,
}: IEmptyContent) => {
    const { themeStyle } = useThemeContext();
    const [innerWidth, setInnerWidth] = useState<any>();
    const styles = dynamicStyles(themeStyle);

    const handleInnerWidth = (innerWidth: number) => {
        setInnerWidth(innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', () => handleInnerWidth(window.innerWidth));
    }, []);

    return (
        <Box style={styles.flexColumn} sx={{ gap: '25px', margin: 10 }}>
            <CustomLazyLoadImage
                src={outlineIcon}
                alt={outlineIconAlt}
                style={{ maxHeight: '150px', maxWidth: 'auto' }}
            />

            <Box style={styles.flexColumn} sx={{ gap: '5px' }}>
                <Typography style={styles.title}>{title}</Typography>
                <Typography style={styles.text}>{description}</Typography>
            </Box>
            <Button
                onClick={onClickHandle}
                style={{ ...styles.link, width: innerWidth > 1000 ? '250px' : '150px' }}
                sx={{ gap: '8px', textDecoration: 'none', '&:hover': { cursor: 'pointer' }, textTransform: 'none' }}>
                <CustomLazyLoadImage src={filledIcon} alt={filledIconAlt} />
                <Typography style={styles.trophyText}>{buttonText}</Typography>
            </Button>
        </Box>
    );
};

const dynamicStyles = (colorScheme: ColorScheme) =>
    StyleSheet.create({
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '344px',
        },
        link: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '168px',
            height: '40px',
            borderRadius: 100,
            border: `2px solid ${theme[colorScheme].primary_orange}`,
        },
        text: {
            fontSize: 14,
            fontWeight: '500',
            fontFamily: FONT_ENUM.MAIN_REGULAR,
            textAlign: 'center',
            color: theme[colorScheme].gray_message,
            maxWidth: '344px',
        },
        title: {
            fontSize: 24,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            textAlign: 'center',
            color: theme[colorScheme].primary_white,
        },
        trophyText: {
            fontSize: 14,
            fontWeight: '600',
            fontFamily: FONT_ENUM.MAIN_BOLD,
            textAlign: 'center',
            color: theme[colorScheme].primary_orange,
        },
    });

export { EsEmptyContentCard };
